import {
  assert,
  withValidation,
  reportWarning,
  reportError,
  messages,
  composeSDKFactories,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import {
  TPAGallerySDKFactory,
  OnItemClickedEvent,
  LinkTarget,
  TPAImage,
} from '../TPAGallery.types';
import { parseMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';
import {
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import { convertImagesToUserModel } from './TPAGallerySDKUtils';

enum GALLERY_CLICK_ACTIONS {
  none = 'disabled',
  expand = 'zoomMode',
  link = 'goToLink',
}

type LINK_TYPE = 'WEBSITE' | 'DYNAMIC_PAGE_LINK';

const tpaGallerySDKFactory: TPAGallerySDKFactory = ({
  setProps,
  registerEvent,
  props,
  linkUtils,
  metaData,
  getSdkInstance,
  sdkData,
}) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );

  function getOrGenerateId(index: number, dataId: string) {
    return `${metaData.compId}_runtime_${dataId}items${index}`;
  }

  function convertImageOrReportError(
    src: string,
    index: number,
    title: string | undefined,
    description: string | undefined,
    alt: string | undefined,
    resolvedLink: any,
    linkType: LINK_TYPE,
  ) {
    const {
      height,
      width,
      title: name,
      error,
      type,
      mediaId: uri,
    } = parseMediaSrc(src, 'image');
    if (error) {
      reportError(
        messages.invalidImageInGalleryWithIndex({
          wrongValue: src,
          index,
          propertyName: 'src',
        }),
      );
      return null;
    }
    const img = {
      id: getOrGenerateId(index, sdkData.dataId),
      type,
      height,
      width,
      title: name,
      uri,
      ...(title && { title }),
      ...(description && { description }),
      ...(alt && { alt }),
      ...(resolvedLink && {
        linkType,
        link: resolvedLink,
        href: resolvedLink.href,
      }),
    };
    return img;
  }
  return {
    get items() {
      const imageItems = props.images.filter(
        (image: TPAImage) =>
          !image.type || image.type.toLowerCase() === 'image',
      );
      const convertModelToUserFormat = convertImagesToUserModel(
        imageItems,
        linkUtils,
      );
      return convertModelToUserFormat;
    },
    set items(items) {
      const images = items.filter(
        (image: TPAImage) =>
          !image.type || image.type.toLowerCase() === 'image',
      );
      if (images.length !== items.length) {
        reportWarning(messages.noneImageInGallery(metaData.role));
      }
      const imagesModel = images
        .map(
          (
            image: {
              src: string;
              title?: string;
              description?: string;
              alt?: string;
              link?: string;
              target?: LinkTarget;
            },
            index: number,
          ) => {
            const { title, description, alt, link, src, target } = image;
            let linkType: LINK_TYPE = 'WEBSITE';
            let resolvedLink;
            if (link) {
              const linkTarget = target ? target : '_self';
              try {
                resolvedLink = linkUtils.getLinkProps(link, linkTarget);
                if (linkUtils.isDynamicPage(link)) {
                  linkType = 'DYNAMIC_PAGE_LINK';
                }
              } catch (e) {
                resolvedLink = {
                  href: link,
                  target: target ? target : '_blank',
                };
              }
            }
            return convertImageOrReportError(
              src,
              index,
              title,
              description,
              alt,
              resolvedLink,
              linkType,
            );
          },
        )
        .filter((x: any) => x !== null);

      if (images.length !== imagesModel.length) {
        return;
      }

      setProps({ images: imagesModel });
    },

    get clickAction() {
      const { compProps } = props;

      const action: 'disabled' | 'zoomMode' | 'goToLink' =
        compProps.galleryImageOnClickAction;

      switch (action) {
        case 'disabled':
          return 'none';
        case 'goToLink':
          return 'link';
        default:
        case 'zoomMode':
          return 'expand';
      }
    },
    set clickAction(action) {
      const galleryImageClickAction = assert.isNil(action)
        ? GALLERY_CLICK_ACTIONS.none
        : GALLERY_CLICK_ACTIONS[action];
      setProps({
        compProps: {
          ...props.compProps,
          galleryImageOnClickAction: galleryImageClickAction,
        },
      });
    },
    onItemClicked(handler) {
      if (!functionValidator(handler, 'onItemClicked')) {
        return getSdkInstance();
      }

      registerEvent('onItemClicked', (event: OnItemClickedEvent) => {
        const convertedItemToUserModel = convertImagesToUserModel(
          [event.item],
          linkUtils,
        );
        [event.item] = convertedItemToUserModel;
        handler(event);
      });

      return getSdkInstance();
    },
    get type() {
      return '$w.Gallery';
    },

    toJSON() {
      const { items } = getSdkInstance();

      return {
        ...toJSONBase(metaData),
        items,
        type: '$w.Gallery',
      };
    },
  };
};

const tpaGallerySDK = withValidation(tpaGallerySDKFactory, {
  type: ['object'],
  properties: {
    clickAction: {
      warnIfNil: true,
      type: ['string'],
      enum: Object.keys(GALLERY_CLICK_ACTIONS),
    },

    items: {
      type: ['array'],
      items: {
        type: ['object'],
        properties: {
          src: { type: ['string', 'nil'], warnIfNil: true },
          alt: { type: ['string', 'nil'], warnIfNil: true },
          name: { type: ['string', 'nil'], warnIfNil: true },
        },
      },
      warnIfNil: true,
    },
  },
});

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const tpaGalleryPropsSDKFactory = composeSDKFactories(
  elementPropsSDKFactory,
  tpaGallerySDK,
);
